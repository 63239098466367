// ** React & Next
import { useEffect, useState } from 'react';
import Image from 'next/image';

// ** MUI Imports
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

// ** Hooks
import { Connector, useAccount, useConnect, useDisconnect } from 'wagmi';
import { useModalsActions } from '@/context/modals';

// ** Utils & Types
import { BaseMUIButtonProps, SignInStatus } from '@/types/custom';
import getWalletDetails from '@/helpers/getWalletDetails';
import { USER_REJECTED_CODE_ERROR } from '@/utils/constants/literals';
import { setSignInStatus } from '@/utils/helpers';
import { MonitorError } from '@/utils/error';
import { IconX } from '@tabler/icons-react';
export const WalletOptions = () => {
  const {
    connectors,
    connectAsync,
    error
  } = useConnect();
  const {
    connector: activeConnector
  } = useAccount();
  const {
    disconnect
  } = useDisconnect();
  const {
    close: closeModal,
    open: openModal
  } = useModalsActions();
  const onConnect = async (connector: Connector) => {
    try {
      closeModal('connectWallet');
      await connectAsync({
        connector
      });
      setSignInStatus(SignInStatus.pending);
      openModal('signInProcess');
    } catch (error: any) {
      if (error?.code === USER_REJECTED_CODE_ERROR) return;
      throw new MonitorError(error, {
        tags: {
          component: 'WalletOptions',
          section: 'connect'
        }
      });
    }
  };
  const handleConnect = async (connector: Connector) => {
    try {
      await onConnect(connector);
    } catch (error) {
      console.log('Error connecting wallet: ', error);
    }
  };
  return <Stack gap={1} data-sentry-element="Stack" data-sentry-component="WalletOptions" data-sentry-source-file="LoginWallet.tsx">
      {activeConnector?.id ? <Button onClick={() => disconnect?.()}>Change wallet</Button> : <>
          {connectors.filter((connector: any, index: number, self: any) => self.findIndex((c: any) => c.type === connector.type) === index).map((connector: any) => {
        return <WalletOption key={connector.uid} connector={connector} onClick={() => handleConnect(connector)} />;
      })}
        </>}
      {error?.message ? <Box display="flex" alignItems="center" color={"red"} gap={1}>
          <IconX size={16} />
          <Typography textAlign={'center'}>
            {error?.message || 'Failed to connect'}
          </Typography>
        </Box> : null}
    </Stack>;
};
const WalletOption = ({
  connector,
  onClick
}: {
  connector: Connector;
  onClick: () => void;
}) => {
  const [ready, setReady] = useState(false);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    ;
    (async () => {
      const provider = await connector.getProvider();
      setReady(!!provider);
    })();
  }, [connector]);
  return <Button size="small" disabled={!ready} onClick={onClick} variant="outlined" data-sentry-element="Button" data-sentry-component="WalletOption" data-sentry-source-file="LoginWallet.tsx">
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} m={1} data-sentry-element="Stack" data-sentry-source-file="LoginWallet.tsx">
        <Typography data-sentry-element="Typography" data-sentry-source-file="LoginWallet.tsx">
          {connector.id === 'injected' ? 'Browser wallet' : getWalletDetails(connector.name).name}
        </Typography>
        <Image src={getWalletDetails(connector.name).logo} width={isSmScreen ? 20 : 30} height={isSmScreen ? 20 : 30} alt="" data-sentry-element="Image" data-sentry-source-file="LoginWallet.tsx" />
      </Stack>
    </Button>;
};
type Props = BaseMUIButtonProps & {
  /**
   * The text to be displayed inside the button.
   * @default "Connect wallet"
   */
  label?: string;
  /**
   * Callback fired before the subscription takes place.
   */
  beforeOpen?: () => void;
};
export const LoginWallet = ({
  variant = 'contained',
  size = 'medium',
  label = 'Login with Lens',
  color = 'primary',
  beforeOpen
}: Props) => {
  const {
    open: openModal
  } = useModalsActions();
  return <>
      <Button variant={variant} size={size} color={color} onClick={() => {
      beforeOpen?.();
      openModal('connectWallet');
    }} data-sentry-element="Button" data-sentry-source-file="LoginWallet.tsx">
        <Stack direction="row" data-sentry-element="Stack" data-sentry-source-file="LoginWallet.tsx">{label}</Stack>
      </Button>
    </>;
};