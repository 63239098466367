import { uniqueId } from 'lodash'

interface MenuitemsType {
  [x: string]: any
  id?: string
  navlabel?: boolean
  subheader?: string
  title?: string
  icon?: any
  href?: string
  children?: MenuitemsType[]
  chip?: string
  chipColor?: string
  variant?: string
  external?: boolean
  isProductionMode: boolean
  hide?: boolean
}
import {
  IconApps,
  IconAperture,
  IconUsers,
  IconUsersGroup,
  IconAward,
  IconList,
  IconPoint,
  IconTrophy,
  IconDashboard,
  IconHeartSearch,
  IconAlignBoxLeftBottom,
} from '@tabler/icons-react'
import { isProductionMode } from '@/utils'

const items: MenuitemsType[] = [
  {
    navlabel: true,
    subheader: 'Feed',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Feed',
    icon: IconList,
    href: '/',
    chipColor: 'secondary',
    isProductionMode: true,
  },
  {
    navlabel: true,
    subheader: 'Trending',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'People',
    icon: IconUsers,
    href: '/people',
    chipColor: 'secondary',
    isProductionMode: true,
    children: [
      {
        id: uniqueId(),
        title: 'Most liked',
        icon: IconPoint,
        href: '/people/?liked',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most followed',
        icon: IconPoint,
        href: '/people/?followed',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most collected',
        icon: IconPoint,
        href: '/people/?collected',
        isProductionMode: true,
      },
    ],
  },
  {
    id: uniqueId(),
    title: 'Content',
    icon: IconAperture,
    href: '/content',
    chipColor: 'secondary',
    isProductionMode: true,
    children: [
      {
        id: uniqueId(),
        title: 'Most liked',
        icon: IconPoint,
        href: '/content?liked',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most commented',
        icon: IconPoint,
        href: '/content?commented',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most quoted',
        icon: IconPoint,
        href: '/content?quoted',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most mirrored',
        icon: IconPoint,
        href: '/content?mirrored',
        isProductionMode: true,
      },
    ],
  },
  {
    navlabel: true,
    subheader: 'Collections & Revenue',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Collect',
    icon: IconAward,
    href: '/collect',
    chipColor: 'secondary',
    isProductionMode: true,
    children: [
      {
        id: uniqueId(),
        title: 'Most collected free',
        icon: IconPoint,
        href: '/collect?collectedFree',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most collected paid',
        icon: IconPoint,
        href: '/collect?collectedPaid',
        isProductionMode: true,
      },
    ],
  },
  {
    id: uniqueId(),
    title: 'Zora',
    icon: IconAlignBoxLeftBottom,
    href: '/zora',
    isProductionMode: false,
  },
  {
    id: uniqueId(),
    title: 'Top creators',
    icon: IconTrophy,
    href: '/topCreators',
    isProductionMode: false,
  },
  {
    navlabel: true,
    subheader: 'Dashboard',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Dashboard',
    icon: IconDashboard,
    href: '/dashboard',
    chipColor: 'secondary',
    isProductionMode: false,
  },
  {
    id: uniqueId(),
    title: 'Topics',
    icon: IconHeartSearch,
    href: '/topics',
    chipColor: 'secondary',
    isProductionMode: false,
  },
  {
    navlabel: true,
    subheader: 'Communities',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Orb clubs',
    icon: IconUsersGroup,
    href: '/orbClubs',
    isProductionMode: true,
  },
  {
    navlabel: true,
    subheader: 'Apps',
    isProductionMode: false,
    hide: true,
  },
  {
    id: uniqueId(),
    title: 'Stats',
    icon: IconApps,
    href: '/apps',
    isProductionMode: false,
    hide: true,
  },
]

const Menuitems = isProductionMode
  ? items.filter((item) => item.isProductionMode)
  : items

export default Menuitems
