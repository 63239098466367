// ** React, Next & MUI
import Image from 'next/image';
import { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Button, Stack, CircularProgress, Box, Typography, useTheme, Divider, TextField, useMediaQuery } from '@mui/material';

// ** Components
import BaseModal from './base';

// ** Context
import { useModalsActions, useModalsState } from '@/context/modals';
import { useLens } from '@/context/lens';
import { appendMentionPrefix, base64ToFile } from '@/utils/helpers';
import { useIPFSStorage } from '@/context/IPFSStorage';
import { getIPFSGateway } from '@/utils/constants/api';
import { useSession } from '@/context/session';

/**
 * Payload for the Share Stats modal
 * @param screenShoot - The ScreenShoot of the chart stats to be shared.
 */
export interface ShareStatsPayload {
  screenShoot: string;
  engagements?: any[];
  engagementType?: string;
}

/////////////////////////////////////
export const ShareStatsModal = () => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    close
  } = useModalsActions();
  const {
    shareStats
  } = useModalsState();
  const {
    loggedIn
  } = useSession();
  const {
    user,
    client: lensClient,
    createImageMetadata,
    createPost: createLensPost
  } = useLens();
  const {
    uploadJSON,
    uploadImage
  } = useIPFSStorage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [screenShoot, setScreenShoot] = useState<string | null>(null);
  const [input, setInput] = useState<string>('');
  /*************************************************
   *                    Render                      *
   *************************************************/

  useEffect(() => {
    setIsLoading(true);
    if (shareStats.data?.screenShoot) {
      setScreenShoot(shareStats.data?.screenShoot);
    }
    if (shareStats.data?.engagements?.length) {
      const engagementMentions = shareStats.data.engagements.map(engagement => `@${engagement.handle.localName}`).join(' ');
      if (shareStats.data?.engagementType === 'inbound') {
        setInput(`A big thank you to my greatest supporters this week!🎉: ${engagementMentions}`);
      } else {
        setInput(`Check out who I have been supporting this week! 💪🏼: ${engagementMentions}`);
      }
    }
    setIsLoading(false);
    return () => {
      setInput('');
      setScreenShoot(null);
    };
  }, [shareStats.isOpen, shareStats.data?.screenShoot]);

  /*************************************************
   *                  Handlers                     *
   *************************************************/

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };
  const handleCloseModal = () => {
    close('shareStats');
  };
  const handleConfirmClick = async () => {
    setSubmitting(true);
    await handleSubmit();
  };
  const handleSubmit = async () => {
    try {
      if (!loggedIn || !user.profileId) throw new Error('User must be signed-in to publish.');
      if (!lensClient) throw new Error('Need to initialize lens client first.');
      const processedText = appendMentionPrefix(input);
      if (!screenShoot) throw new Error('No screenshot provided');
      const parsedImage = base64ToFile(screenShoot, 'statsScreenShoot');
      const imageCid = await uploadImage(parsedImage);
      if (!imageCid) throw new Error('Error uploading image to IPFS');
      const imageMetadata = getIPFSGateway(imageCid);
      const postMetadata = await createImageMetadata('My stats', imageMetadata, 'ScoreNFT', processedText);
      if (postMetadata === null) throw new Error('Error creating metadata');
      const metadataCID = await uploadJSON(postMetadata);
      if (!metadataCID) throw new Error('Error uploading metadata to IPFS');
      const createdPost = await createLensPost(metadataCID);
      if (!createdPost || createdPost.isFailure() || createdPost.value.__typename === 'LensProfileManagerRelayError') {
        throw new Error('Error creating post on Lens');
      }
    } catch (err) {
      console.log(`Error sharing stats: ${err}`);
    } finally {
      handleCloseModal();
      setSubmitting(false);
    }
  };
  return <Modal open={shareStats.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-component="ShareStatsModal" data-sentry-source-file="share-stats.tsx">
      <BaseModal sx={{
      justifyContent: 'center',
      width: isMdScreen ? '90vw' : undefined,
      maxHeight: isMdScreen ? '100vh' : '90vh',
      maxWidth: isMdScreen ? '100v' : '50vw'
    }} data-sentry-element="BaseModal" data-sentry-source-file="share-stats.tsx">
        {isLoading ? <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
            <CircularProgress />
          </Box> : <Stack spacing={4}>
            <Stack>
              <Typography variant="h5" textAlign="center">
                Share your stats!
              </Typography>
              <Divider sx={{
            mt: '0.5em',
            mb: '1em'
          }} />

              <Stack spacing={2}>
                <Box sx={{
              width: '100%',
              height: 'auto',
              maxHeight: '50vh',
              overflow: 'hidden',
              mb: 2,
              border: '2px dashed',
              borderColor: theme.palette.grey[300],
              borderRadius: '10px',
              padding: '1em',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
                  {screenShoot && <Image src={screenShoot} alt="Chart screenshot" layout="intrinsic" width={500} height={300} objectFit="contain" />}
                </Box>
                <TextField id="hey-comment" label="Your thoughts..." multiline size="medium" rows={2} value={input} onChange={handleTextChange} />
              </Stack>
            </Stack>
            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2}>
              <Button color="success" size="large" disabled={submitting} onClick={handleConfirmClick}>
                {!submitting ? 'Post' : <CircularProgress />}
              </Button>
              <Button color="error" size="large" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Stack>
          </Stack>}
      </BaseModal>
    </Modal>;
};