// ** React Imports
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

// ** MUI Imports
import { Avatar, Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

// ** Hooks
import { useLens } from '@/context/lens';
import { useAccount } from 'wagmi';

// ** Components
import { BaseSignature } from './BaseSignature';

// ** Utils & Types
import { SignatureStatus } from '.';
import { generateLensSignature } from '@/utils/helpers';
import { USER_REJECTED_CODE_ERROR } from '@/utils/constants/literals';
export type LensProfileData = {
  id: string;
  handle: string;
  name: string;
  img: string;
};
type LensSign = {
  status: SignatureStatus;
  profiles: LensProfileData[];
};
type LensSignatureProps = LensSign & {
  setStatus: Dispatch<SetStateAction<SignatureStatus>>;
};
type ProfileBoxProps = {
  profile: LensProfileData;
  handleAuthenticate: (id?: string) => void;
};
const ProfileBox = ({
  profile,
  handleAuthenticate
}: ProfileBoxProps) => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  return <Box sx={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 0.5,
    px: 1,
    gap: isMdScreen ? 1 : 2,
    borderRadius: '0.5rem',
    border: `1px solid ${theme.palette.divider}`,
    width: '100%'
  }} data-sentry-element="Box" data-sentry-component="ProfileBox" data-sentry-source-file="LensSignature.tsx">
      <Stack direction={'row'} alignItems={'center'} gap={{
      xs: 1,
      md: 2
    }} data-sentry-element="Stack" data-sentry-source-file="LensSignature.tsx">
        <Avatar src={profile.img} sx={{
        width: 32,
        height: 32
      }} data-sentry-element="Avatar" data-sentry-source-file="LensSignature.tsx" />
        <Stack direction={'column'} gap={0} data-sentry-element="Stack" data-sentry-source-file="LensSignature.tsx">
          <Typography variant="subtitle1" color="textPrimary" data-sentry-element="Typography" data-sentry-source-file="LensSignature.tsx">
            {profile.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" data-sentry-element="Typography" data-sentry-source-file="LensSignature.tsx">
            {profile.handle}
          </Typography>
        </Stack>
      </Stack>
      <Button variant="contained" color="primary" size="small" onClick={() => handleAuthenticate(profile.id)} data-sentry-element="Button" data-sentry-source-file="LensSignature.tsx">
        Login
      </Button>
    </Box>;
};
type ProfileListProps = {
  profiles: LensProfileData[];
  handleAuthenticate: (id?: string) => void;
};
const ProfileList = ({
  profiles,
  handleAuthenticate
}: ProfileListProps) => <Stack direction={'column'} alignItems={'center'} gap={1} sx={{
  overflowY: 'auto'
}} data-sentry-element="Stack" data-sentry-component="ProfileList" data-sentry-source-file="LensSignature.tsx">
    {profiles.map(item => <ProfileBox key={item.id} profile={item} handleAuthenticate={handleAuthenticate} />)}
  </Stack>;
export const LensSignature = ({
  profiles,
  status,
  setStatus
}: LensSignatureProps) => {
  const [selectedProfile, setSelectedProfile] = useState<string>();
  const {
    authenticate
  } = useLens();
  const {
    address
  } = useAccount();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleAuthenticate = useCallback(async (id?: string): Promise<any | undefined> => {
    setStatus('pending');
    try {
      if (id) setSelectedProfile(id);
      const walletId = address?.toLowerCase();
      if (!walletId) throw new Error('No wallet connected ');
      const jwt = await generateLensSignature(authenticate, id);
      if (jwt === null) throw new Error('No jwt generated.');
      setStatus('success');
      return true;
    } catch (error: any) {
      if (error?.code !== USER_REJECTED_CODE_ERROR) {
        console.log('Error authenticating with lens', error);
      }
      setStatus('error');
    }
  }, [setStatus]);
  const title = useMemo(() => {
    if (!profiles.length) return 'Login';
    if (isMdScreen) return 'Login';
    return 'Login with Lens';
  }, [isMdScreen, profiles.length]);
  if (status === undefined && !profiles.length) {
    return <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      p: 2,
      my: 0.5,
      borderRadius: '0.5rem',
      border: `1px solid ${theme.palette.divider}`,
      width: '100%',
      minHeight: '4rem'
    }}>
        <Typography variant="subtitle2" color="textSecondary" textAlign={'center'}>
          You must have a Lens handle to sign-in
        </Typography>
      </Box>;
  }
  return <BaseSignature status={status} setStatus={setStatus} callFunction={() => handleAuthenticate(selectedProfile)} title={title} subTitle={profiles.length > 1 ? '(Select one)' : undefined} ctaLabel="Login" ctaContent={profiles.length && status === undefined ? <ProfileList profiles={profiles} handleAuthenticate={handleAuthenticate} /> : undefined} data-sentry-element="BaseSignature" data-sentry-component="LensSignature" data-sentry-source-file="LensSignature.tsx" />;
};