// ** React & MUI
import { useEffect, useState } from 'react';
import { Modal, Button, Stack, CircularProgress, Box, Typography, Divider, useTheme, Chip, Avatar } from '@mui/material';

// ** Components
import BaseModal from './base';

// ** Context
import { useModalsActions, useModalsState } from '@/context/modals';
import { useLens } from '@/context/lens';
import { PostFragment, LegacySimpleCollectModuleSettingsFragment } from '@lens-protocol/client';
import { getAvatarUrl } from '@/utils';
import Link from 'next/link';

/**
 * Payload for the Collect modal
 * @param publicationId - The publicationId of the publication to be collected.
 */
export interface CollectPayload {
  publicationId: string;
  onSuccess: () => void;
}

/////////////////////////////////////
export const CollectModal = () => {
  const theme = useTheme();
  const {
    close
  } = useModalsActions();
  const {
    collect: collectModal
  } = useModalsState();
  const {
    isOpen,
    data: modalData
  } = collectModal;
  const {
    fetchCollectCriteria: getPostCollectCriteria,
    collect,
    follow
  } = useLens();
  const [postId, setPostId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [post, setPost] = useState<PostFragment>();
  const [canCollect, setCanCollect] = useState<boolean>();
  const [isCollected, setIsCollected] = useState<boolean>();
  const [isFollowing, setIsFollowing] = useState<boolean>();
  const [collectSettings, setCollectSettings] = useState<LegacySimpleCollectModuleSettingsFragment>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loadingFollow, setLoadingFollow] = useState<boolean>(false);

  /*************************************************
   *                    Render                      *
   *************************************************/

  useEffect(() => {
    if (!isOpen || !modalData?.publicationId) return;
    const {
      publicationId
    } = modalData;
    setPostId(publicationId);
    getCollectCriteria(publicationId);
    return cleanState;
  }, [modalData, isOpen, refresh]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const getCollectCriteria = async (publicationId: string) => {
    try {
      const collectCriteria = await getPostCollectCriteria(publicationId);
      if (!collectCriteria) throw new Error('No collect criteria found.');
      setPost(collectCriteria.post);
      setIsFollowing(collectCriteria.post.by.operations.isFollowedByMe.value);
      setCollectSettings(collectCriteria.settings as any);
      setCanCollect(collectCriteria.canCollect === 'YES');
      setIsCollected(collectCriteria.post.operations.hasCollected.value);
    } catch (error) {
      console.log('Error while running collect criteria function: ', error);
    } finally {
      setIsLoading(false);
    }
  };
  const cleanState = () => {
    setPost(undefined);
    setIsFollowing(undefined);
    setCollectSettings(undefined);
    setCanCollect(undefined);
    setIsCollected(undefined);
    setIsLoading(true);
  };
  const handleCloseModal = () => {
    close('collect');
  };
  const handleConfirmClick = async () => {
    setSubmitting(true);
    await handleSubmit();
  };
  const handleSubmit = async () => {
    try {
      if (!postId) throw new Error('No publication id provided');
      const lensRes = await collect(postId);
      if (collectModal.data?.onSuccess && lensRes?.isSuccess) collectModal.data.onSuccess();
      handleCloseModal();
    } catch (err) {
      console.log('Error collecting publication', err);
    } finally {
      setSubmitting(false);
    }
  };
  const handleFollow = async () => {
    setLoadingFollow(true);
    try {
      if (!post?.by.id) return;
      await follow(post.by.id);
      setRefresh(true);
    } catch (error) {
      console.log('Error following user:', error);
    } finally {
      setLoadingFollow(false);
    }
  };
  const userProfilePath = post ? `/profile/${post.by.id}` : '/';
  return <Modal open={collectModal.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-component="CollectModal" data-sentry-source-file="collect.tsx">
      <BaseModal sx={{
      justifyContent: 'center'
    }} data-sentry-element="BaseModal" data-sentry-source-file="collect.tsx">
        {isLoading ? <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
            <CircularProgress />
          </Box> : <Stack spacing={4}>
            {canCollect ? <Stack gap={0.4}>
                <Typography variant="h5" textAlign="center">
                  Collect publication
                </Typography>
                <Divider sx={{
            mt: '0.5em'
          }} />
                <Stack sx={{
            mt: '1em'
          }} direction="row" alignItems="center" gap={0.5}>
                  <Typography variant="subtitle2" sx={{
              fontWeight: 'bold'
            }}>
                    Post by:
                  </Typography>
                  <Link href={userProfilePath} onClick={handleCloseModal} style={{
              textDecoration: 'none',
              color: 'inherit'
            }}>
                    <Box sx={{
                '&:hover': {
                  cursor: 'pointer',
                  '& .avatar': {
                    filter: 'brightness(0.5)',
                    transition: '0.2s'
                  },
                  '& .name': {
                    color: theme.palette.primary.main,
                    transition: '0.2s'
                  }
                }
              }}>
                      <Chip avatar={<Avatar src={getAvatarUrl(post?.by)} alt={post?.by?.handle?.localName} className="avatar" />} label={post?.by?.handle?.localName} className="name" color="default" sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }} />
                    </Box>
                  </Link>
                </Stack>
                {collectSettings?.amount.value !== '0' && <>
                    <Stack direction="row" gap={0.5}>
                      <Typography variant="subtitle2" sx={{
                fontWeight: 'bold'
              }}>
                        Collect ammount:
                      </Typography>
                      <Typography variant="subtitle2">
                        {`${collectSettings?.amount.value} ${collectSettings?.amount.asset.name}`}
                      </Typography>
                    </Stack>
                    {collectSettings?.amount?.rate?.value && <Stack direction="row" gap={0.5}>
                        <Typography variant="subtitle2" sx={{
                fontWeight: 'bold'
              }}>
                          Collect fee ammount:
                        </Typography>
                        <Typography variant="subtitle2">
                          {`${collectSettings?.amount?.rate?.value} ${collectSettings?.amount?.rate?.asset.name}`}
                        </Typography>
                      </Stack>}
                  </>}
                {collectSettings?.followerOnly && !isFollowing && <Box display="flex" flexDirection="column" sx={{
            mt: '1em'
          }}>
                    <Typography variant="caption" color={theme.palette.error.dark}>
                      You need to follow the publication owner to succesfully
                      collect this post
                    </Typography>
                    <Button size="small" variant="outlined" color="info" onClick={handleFollow} disabled={loadingFollow}>
                      {loadingFollow ? <CircularProgress size={20} /> : 'Follow'}
                    </Button>
                  </Box>}
              </Stack> : <Box>
                <Typography textAlign="center">
                  You can no longer collect this publication :(
                </Typography>
              </Box>}
            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2}>
              <Button color="success" variant="contained" size="large" disabled={submitting || !canCollect || isCollected || collectSettings?.followerOnly && !isFollowing} onClick={handleConfirmClick}>
                {submitting ? <CircularProgress /> : isCollected ? 'Collected' : 'Collect'}
              </Button>
              <Button color="error" size="large" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Stack>
          </Stack>}
      </BaseModal>
    </Modal>;
};